import PropTypes from "prop-types";
import React, { useContext, useState, useEffect } from "react";
import { navigate } from "gatsby";

import { CurrentUserContext } from "../providers/auth";

import Layout from "../components/layout";
import Loading from "../components/loading";
import Icon, { Link } from "../components/icon";
import Links, { getLinkDescs, removeTrailingSlash } from "../utils/links";
import {
    getRedirectUrl,
    removeRedirectUrl,
    getLastTimeoutLogoutUser,
    removeLastTimeoutLogoutUser
} from "../utils/session";

const IndexPage = () => (
    <Layout link={Links.Home} seoTitle="Home">
        <Index />
    </Layout>
);

export default IndexPage;

const Index = () => {
    const currentUser = useContext(CurrentUserContext);
    const [checkedRedirect, setCheckedRedirect] = useState(false);
    const [links, setLinks] = useState(null);

    useEffect(() => {
        if (currentUser) {
            currentUser.getUser().then((user) => {
                const timeoutUser = getLastTimeoutLogoutUser();
                const redirectUrl = getRedirectUrl();
                if (user) {
                    removeLastTimeoutLogoutUser();
                    setLinks(getLinkDescs({ user }));
                }
                if (user || !timeoutUser) {
                    removeRedirectUrl();
                }
                if (
                    redirectUrl &&
                    user &&
                    (!timeoutUser ||
                        (timeoutUser &&
                            timeoutUser === user.profile.sub &&
                            removeTrailingSlash(window.location.href) !==
                                removeTrailingSlash(redirectUrl)))
                ) {
                    window.location.href = redirectUrl;
                } else {
                    setCheckedRedirect(true);
                }
            });
        }
    }, [currentUser]);

    return checkedRedirect ? (
        links !== null && links.length !== 0 && <Welcome links={links} />
    ) : (
        <Loading />
    );
};

const Welcome = ({ links }) => (
    <>
        <div className="title-h1">Welcome</div>
        <div>
            In PAQS Admin Portal, you can administrate tasks in following
            categories:
        </div>
        {links.map((link, idx) => (
            <div
                key={idx}
                className="w-1/2 lg:w-1/3 p-4 inline-block align-top"
            >
                <div className="w-full rounded bg-gray-200 p-4 text-left">
                    <button
                        className="btn-primary text-left w-full title-h2 hover:bg-gray-200"
                        onClick={() => navigate(link.link)}
                    >
                        <Icon
                            name={Link}
                            width="20px"
                            cssClass="inline-block"
                        />{" "}
                        {link.name}
                    </button>
                    <div className="m-2">{link.desc}</div>
                    {link.subLinks &&
                        link.subLinks.map((sl, sidx) => (
                            <div
                                className="w-full my-2 px-2 align-top"
                                key={sidx}
                            >
                                <button
                                    className="btn-secondary w-full hover:bg-gray-200"
                                    onClick={() => {
                                        navigate(sl.link);
                                    }}
                                >
                                    {sl.name}
                                </button>
                            </div>
                        ))}
                </div>
            </div>
        ))}
    </>
);

Welcome.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired,
            desc: PropTypes.string.isRequired,
            subLinks: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    link: PropTypes.string.isRequired
                })
            )
        })
    ).isRequired
};
